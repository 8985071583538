
<template>
  <div class="Articles_I">
    <div class="headNavs">
      <div class="headTilte">内部文章</div>
      <div class="headDetails">说明：包含销售、材料、运营等常见业务高度相关的报名网站、查询网址、素菜网站、考试网站、学习网站。</div>
    </div>

    <div class="searchNav">
      <div class="NavLi">
          <div class="NavLiName">文章类型：</div>
          <div class="NavLiIpt">
            <el-select v-model="search.category_id" placeholder="选择文章类型">
              <el-option v-for="item in categoryList" :key="item.id" :label="item.name" :value="item.id"></el-option>
            </el-select>
          </div>
      </div>
      <div class="NavLi">
          <div class="NavLiName">提交人：</div>
          <div class="NavLiIpt">
            <el-select v-model="search.staff_id" placeholder="选择提交人">
              <el-option v-for="item in staffList" :key="item.id" :label="item.realname" :value="item.id"></el-option>
            </el-select>
          </div>
      </div>
      <div class="NavLi">
        <div class="NavLiName">创建时间：</div>
        <div class="NavLiIpt">
          <el-date-picker v-model="search.time.min" type="date" placeholder="选择月份" :clearable="false"></el-date-picker>
          <span class="timeSpan">至</span>
          <el-date-picker v-model="search.time.max" type="date" placeholder="选择月份" :clearable="false"></el-date-picker>
        </div>
        <div class="NavLiQuery" style="margin-left:24px" @click="BtnSearch()">查询</div>
        <div class="NavLiReset" @click="BtnSearch(false)">重置</div>
      </div>
      <div class="NavLI"></div>
      <div class="NavLI"></div>
      <div class="NavLI"></div>
    </div>

    <div class="TablesList">
      <div class="TablesADD">
        <div class="NavLiReset" @click="BtnSearch(false)"><i class="el-icon-refresh"></i>刷新</div>
        <div class="NavLiQuery" @click="showAdd()"><i class="el-icon-plus"></i>创建文章</div>
        <div class="NavLiDelte" @click="Add_Del()"><i class="el-icon-delete"></i>批量删除</div>
        <div class="TableIpt">
          <input @keyup.enter.native="BtnSearch()" type="text" v-model="search.keyword" placeholder="请输入标题">
          <div class="seso" @click="BtnSearch()"><i class="el-icon-search"></i></div>
        </div>
      </div>
      <el-table
        :data="tableData"
        class="table"
        ref="multipleTable"
        header-cell-class-name="table-header"
        @selection-change="handhange">
        <el-table-column type="selection" width="55" align="center"></el-table-column>
        <el-table-column prop="id" label="ID" width="65" align="center"></el-table-column>
        <el-table-column prop="intro" label="标题" width="650" align="center"></el-table-column>
        <el-table-column prop="staff.realname" label="发布人" align="center"></el-table-column>
        <el-table-column prop="category.name" label="类型" align="center"></el-table-column>
        <el-table-column prop="createtime" label="发布时间" align="center"></el-table-column>
        <el-table-column label="操作" align="center" width="200">
          <template #default="scope" >
              <div class="el_butto" >
                <el-button
                type="text"
                @click="Bioattt(scope.row)"
                >查看</el-button>
              </div>
              <div class="el_butto" >
                <el-button
                type="text"
                @click="HandleEdit(scope.row)"
                >编辑</el-button>
              </div>
            <div class="el_butto rere" >
                <el-button
                type="text"
                class="red"
                @click="HandleDel(scope.$index, scope.row)"
                >删除</el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div class="block">
        <el-pagination
            background
            @current-change="handleCurrentChange"
            :current-page="page.currentPage"
            :page-size="page.pageSize"
            layout="total, prev, pager, next, jumper"
            :total="total">
        </el-pagination>
      </div>
    </div>


    <div class="show" v-if="show1">
      <div class="rightPanel-background"/>
      <div class="EditNew">
        <div class="EditNewTop">
          <div>{{titleMath}}</div>
          <div @click="Close()"><i class="el-icon-close"></i></div>
        </div>
        <el-form class="EditNewForm" v-if="show2 == 1">
          <el-form-item class="EditNewFormItem">
            <div class="EditNewFormItemName"><span class="signSpan">* </span>文章标题：</div>
            <div class="EditNewFormItemIpt">
              <el-input v-model="Row.title" placeholder="请输文章标题" class="El_ii" ></el-input>
            </div>
          </el-form-item>
          <el-form-item class="EditNewFormItem">
            <div class="EditNewFormItemName"><span class="signSpan">* </span>文章简介：</div>
            <div class="EditNewFormItemIpt">
              <el-input v-model="Row.intro" placeholder="请输入文章简介" class="El_ii" ></el-input>
            </div>
          </el-form-item>
          <el-form-item class="EditNewFormItem">
            <div class="EditNewFormItemName"><span class="signSpan">* </span>作者：</div>
            <div class="EditNewFormItemIpt">
              <el-input v-model="Row.author" placeholder="请输入作者" class="El_ii" ></el-input>
            </div>
          </el-form-item>
          <el-form-item class="EditNewFormItem">
            <div class="EditNewFormItemName"><span class="signSpan">* </span>文章类型：</div>
            <div class="EditNewFormItemIpt">
              <el-select v-model="Row.category_id" placeholder="选择文章类型">
                <el-option v-for="item in categoryList" :key="item.id" :label="item.name" :value="item.id"></el-option>
              </el-select>
            </div>
          </el-form-item>
          <el-form-item class="EditNewFormBtns">
            <div class="EditNewFormItemIpt">
              <div id="demo1"></div>
            </div>
          </el-form-item>
          <el-form-item class="EditNewFormBtns">
            <div class="FormBtns">
              <div v-if="chakan111 == 1" class="FormBtnsQuery" @click="BtnAdd()">创建</div>
              <div v-if="chakan111 == 0" class="FormBtnsQuery" @click="BtnEdit()">保存</div>
              <div class="FormBtnsClose" @click="show1 = false">取消</div>
            </div>
          </el-form-item>
        </el-form>
        <div class="EditNewForm" style="padding: 0 24px;" v-if="show2 == 2">
          <div>
            <div class="Rig_A_E1">{{form.title}}</div>
            <div class="Rig_A_E2">创作者：{{form.author}} &nbsp;&nbsp;&nbsp;  发布日期：{{form.createtime}} &nbsp;&nbsp;&nbsp;   浏览：{{form.views}}</div>
            <div class="Rig_A_E3" v-html="form.content"></div>
          </div>
        </div>
      </div>
    </div>



  </div>
</template>

<script>
import axios from 'axios'
import  TextMoth  from "../../components/utils.vue";
import { fetchData } from "../../api/index";
import wangEditor from 'wangeditor'
export default {
  name: "basetable",
  data() {
    return {
      upload:'https://rest-api.duxin365.com/admin/ajax/upload',
      heads:{token:''},
      total:'',
      page: {   // 分页
        currentPage: 1,
        pageSize: 10,
      },
      //搜索参数
      search: {
        category_id: "",
        staff_id:'',
        time:{
            min:"",
            max:"",
        },
        keyword:''
      },
      Row: {
        category_id: "",
        title: "",
        intro: "",
        author: "",
        content: "",
      },
      categoryList:[],  //类别列表
      staffList:[],  //全部员工列表
      tableData: [],   //总列表
      titleMath:'',
      form:{},
      show1:false,
      show2:'',
      multipleSelection_id:null,   //勾选的信息
      multipleSelection:[],
      chakan111:0,
      wangE:0,
      editorData:'',
      editor:'',
      Bkdi_id:0,
    };
  },
  mounted() {
    var that = this
    var art = TextMoth.getData('workbench:worknav:nav:read')
    let token = window.localStorage.getItem("accessToken")
    that.heads.token = token
    that.loadTable()
    axios.get('/admin/ajax/staffList',{                   // 全部员工列表
        }).then(function (res) {
        if (res.data.code == 0) {
          alert('请求失败');
        } else{
          console.log(res.data.data)
            that.staffList = res.data.data
        }
    })
    axios.get('/admin/workbench/internal/categoryList',{  //类别列表
      }).then(function (res) {
        if (res.data.code == 0) {
          alert('请求失败');
        } else{
            that.categoryList = res.data.data.list
        }
    })
  },
  methods: {
    // 获取列表数据
    loadTable() {
      var that = this
      axios({
        method: 'post',
        url: '/admin/workbench/internal/articleList',
        data: {
          page: that.page.currentPage,
          limit: that.page.pageSize,
          filter: that.search,
        },
      }).then(function (res) {
        that.tableData = res.data.data.rows
        that.total = res.data.data.total
        that.$forceUpdate()
      })
    },
    //查看
    Bioattt(e) {
      var that = this
      that.show1 = true
      that.show2 = 2
      that.titleMath = '阅读文章'
      axios.delete('/admin/workbench/internal/articleInfo',{   // 模块
        params:{
          id: e.id
        }
        }).then(function (res) {
        if (res.code == 0) {
              that.$message.warning(res.data.msg);
        } else{
          that.form = res.data.data.row
        }
      })
    },
    //新增打开
    showAdd() {
      var that = this
      that.show1 = true
      that.show2 = 1
      that.titleMath = '添加'
      that.chakan111 = 1
      that.editorData = ''
      that.Row = {
        category_id: "",
        title: "",
        intro: "",
        author: "",
        content: "",
      };
      if (that.wangE == 20) {
        that.editor.destroy();
      } else {
        that.wangE = 0
      }
      that.init()
    },
    // 新增确定
    BtnAdd() {
      var that = this
        axios({
          method: 'post',
          url: '/admin/workbench/internal/addArticle',
          data: {
            row: {
              category_id: that.Row.category_id,
              title: that.Row.title,
              intro: that.Row.intro,
              author: that.Row.author,
              content: that.editorData
            }
          },
        }).then(function (res) {
            if (res.data.code == 0) {
              that.$message.warning(res.data.msg);
            } else {
              that.$message.success(res.data.msg);
              that.show1 = false
              that.loadTable();
            }
        })
    },
    //编辑保存
    BtnEdit() {
      var that = this
        axios({
          method: 'put',
          url: '/admin/workbench/internal/editArticle',
          data: {
            id: that.Bkdi_id,
            row: {
              category_id: that.Row.category_id,
              title: that.Row.title,
              intro: that.Row.intro,
              author: that.Row.author,
              content: that.editorData
            }
          },
        }).then(function (res) {
            if (res.data.code == 0) {
              that.$message.warning(res.data.msg);
            } else {
              that.$message.success(res.data.msg);
              that.show1 = false
              that.loadTable();
            }
        })
    },
    //编辑详情
    HandleEdit(data) {
      var that = this
      that.show1 = true
      that.show2 = 1
      that.titleMath = '编辑'
      that.chakan111 = 0
      that.Bkdi_id = data.id
      axios.delete('/admin/workbench/internal/articleInfo',{   // 模块
        params:{
            id: data.id
        }
        }).then(function (res) {
        if (res.code == 0) {
            that.$message.warning(res.data.msg);
        } else{
          that.editorData = res.data.data.row.content
          that.Row = res.data.data.row
        }
      })
      if (that.wangE == 20) {
        that.editor.destroy();
      } else {
        that.wangE = 0
      }
      that.init()
    },
    // 搜索
    BtnSearch(e) {
      var that = this
      that.page.currentPage = 1;
      if (e == false) {
        that.search = {
          category_id: "",
          staff_id:'',
          time:{
            min:"",
            max:"",
          },
          keyword:''
        };
      }
      that.loadTable()
    },
    //富文本
    init() {
      var that = this
      setTimeout(function() {
        var Eat = that.Eattr

        console.log('!!!!!!!!!!!!!')
        const editor = new wangEditor(`#demo1`)

        Eat = editor

        that.wangE = 20

        console.log(that.wangE)
        console.log(Eat)
        // 配置 onchange 回调函数，将数据同步到 vue 中
                // 下面两个配置，使用其中一个即可显示“上传图片”的tab。但是两者不要同时使用！！！
        editor.config.uploadImgHeaders = {
            token:that.heads.token
        }

        editor.config.onchange = (newHtml) => {
          console.log(newHtml)
          that.editorData = newHtml
        }

        // editor.config.uploadImgShowBase64 = true   // 使用 base64 保存图片
        editor.config.showLinkImg = false  //关闭网络路径图片方式
        editor.config.uploadImgServer = 'https://rest-api.duxin365.com/admin/ajax/editorUpload'  // 上传图片到服务器
        editor.config.uploadFileName = 'file'
        editor.config.uploadImgTimeout = 50000;
        editor.config.uploadImgHooks = {
          // 图片上传并返回结果，但图片插入错误时触发
          fail: function (xhr, editor, result) {
            console.log(result)
          },
          success: function (xhr, editor, result) {
            // 图片上传并返回结果，图片插入成功之后触发
            console.log(result, 'success')
          }
        }

        editor.create()
        // 创建编辑器

        editor.txt.html(that.editorData) // 重新设置编辑器内容
        that.editor = editor
      },200)
    },
    //关闭
    Close() {
      this.show1 = false;
      this.show2 = false;
    },
    // 勾选
    handhange(e) {
      console.log(e)
      var that = this
      var arr = []
      var acc = []
      if (e.length == 0) {
        that.multipleSelection_id = null
      } else {
        for (let i = 0; i < e.length; i++) {
          const element = e[i];
          arr.push(element.id)
          acc.push(element)
        }
        that.multipleSelection_id = arr
      }
    },
    //删除
    HandleDel(e, data) {
      var that = this
      var arr = []
      //主键ID
      arr.push(data.id)
      that.$confirm("确定要删除吗？", "提示", {
        type: "primary"
      }).then(() => {
        axios({
            method: 'delete',
            url: '/admin/workbench/internal/delArticle',
            data: {
              ids: arr
            }
        }).then(function (res) {
            if (res.data.code == 0) {
              that.$message.warning(res.data.msg);
            } else {
              that.$message.success(res.data.msg);
              that.loadTable();
            }
        })
      })
    },
     //批量删除
    Add_Del(e) {
        var that = this
        var Att = that.multipleSelection
          if (that.multipleSelection_id == null) {      //批量删除
            that.$message.warning('请勾选信息');
          } else {
            that.$confirm("确定要删除吗？", "提示", {
              type: "primary"
          }).then(() => {
            axios({
              method: 'delete',
              url: '/admin/workbench/internal/delArticle',
              data: {
                ids: that.multipleSelection_id
              },
            }).then(function (res) {
                if (res.data.code == 0) {
                    that.$message.warning(res.data.msg);
                } else {
                    that.$message.success(res.data.msg);
                    that.loadTable()
                }
            })
          })
        }

    },
    //分页
    handleCurrentChange(page) {
      var that = this
      that.page.currentPage = page;
      that.loadTable();
    },
  },
};
</script>

<style scoped>
.Articles_I .Rig_A_E1 {
  font-size: 22px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #262626;
  margin-top: 30px;
}
.Articles_I .Rig_A_E2 {
  font-size: 12px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #262626;
  margin: 16px 0 25px;
}
.Articles_I .Rig_A_E3 {
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #262626;
  line-height: 26px;
}
</style>
